// @ts-nocheck
import dayjs from "dayjs";

const calendarDays = () => {
  const days = Array.from(Array(7).keys());
  const sunday = days.shift();
  return [...days, sunday].map((i) => {
    return dayjs().day(i).format("ddd");
  });
};
export default calendarDays;
