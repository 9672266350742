import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calculateDuration from '@justpark/helpers/src/calculateDurationDayjs/calculateDuration';
import getDayjsInstance from '@justpark/helpers/src/dates/getDayjsInstance';
import getDayjsInstanceWithTimezone from '@justpark/helpers/src/dates/getDayjsInstanceWithTimezone';
import getDefaultBookingDurationHours, {
  getDefaultBookingDurationAirportHours
} from './getDefaultBookingDurationHours';

dayjs.extend(advancedFormat);

export const todayTomorrowDateFormat = (
  date: Dayjs,
  year = false,
  hideTime = false,
  t: (b: string, a: string) => void
) => {
  if (!date) {
    return '';
  }
  const dayjsInstance = getDayjsInstance();

  if (date.isSame(dayjsInstance, 'day')) {
    return hideTime
      ? t('dateFormat:today', '{{date, [Today]}}', { date })
      : t('dateFormat:todayAt', '{{date, [Today at] HH:mm}}', { date });
  }

  if (date.isSame(dayjsInstance.add(1, 'days'), 'day')) {
    return hideTime
      ? t('dateFormat:tomorrow', '{{date, [Tomorrow]}}', { date })
      : t('dateFormat:tomorrowAt', '{{date, [Tomorrow at] HH:mm}}', { date });
  }

  if (hideTime) {
    return year
      ? t('dateFormat:dateMonthYear', '{{date,D MMM YYYY}}', { date })
      : t('dateFormat:dateMonth', '{{date,Do MMM}}', { date });
  }

  return year
    ? t('dateFormat:dateMonthYearTime', '{{date,D MMM YYYY [at] HH:mm}}', {
        date
      })
    : t('dateFormat:dateMonthTime', '{{date,Do MMM [at] HH:mm}}', { date });
};

export const formatDurationFromSeconds = (
  seconds: number,
  t: (b: string, a: string) => string
) =>
  calculateDuration(
    getDayjsInstance(),
    getDayjsInstance().add(seconds, 'seconds'),
    false,
    t
  );

export const defaultSearchDates = (timezone, locale) => {
  const now = timezone
    ? getDayjsInstanceWithTimezone(locale, timezone)
    : getDayjsInstance();

  const arriving = now.add(59, 'minutes').startOf('hour');
  const leaving = arriving.add(getDefaultBookingDurationHours(), 'hours');

  return {
    arriving,
    leaving
  };
};

export const defaultAirportSearchDates = (timezone, locale, duration, lead) => {
  const now = timezone
    ? getDayjsInstanceWithTimezone(locale, timezone)
    : getDayjsInstance();

  const arriving = now.add(lead, 'day').startOf('hour');
  const leaving = arriving.add(duration, 'days');

  return {
    arriving,
    leaving
  };
};

export default null;
