// Month is 0 index based (0 === Jan)
const firstDayOfMonth = (month: number, year: number) => {
  if (month < 0 || month > 11) {
    throw new Error("Invalid month");
  }

  const d = new Date(year, month, 1);
  return d.getDay();
};

export default firstDayOfMonth;
