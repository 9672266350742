// @ts-nocheck
import dayjs from "dayjs";

const getCalendarDateData = (selectedDate: string) => {
  const now = dayjs();
  const currentDay = now.get("date");
  const currentMonth = now.get("month");
  const currentYear = now.get("year");
  const selectedDateInstance = dayjs(selectedDate);
  const selectedDay = selectedDateInstance.get("date");
  const selectedMonth = selectedDateInstance.get("month");
  const selectedYear = selectedDateInstance.get("year");
  return {
    currentDay,
    currentMonth,
    currentYear,
    selectedDay,
    selectedMonth,
    selectedYear
  };
};
export default getCalendarDateData;
