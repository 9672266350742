import range from "./range";

const mapMinuteRange = (minuteStep: number) =>
  range(0, 60, minuteStep).map((i) => {
    const value = `0${i}`.substr(-2);

    return {
      value
    };
  });

export default mapMinuteRange;
