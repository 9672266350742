// @ts-nocheck
const range = (start, end, step = 1) => {
  const arr = [];

  for (let i = 0; i < end / step; i += 1) {
    arr.push(i * step);
  }

  return arr;
};

export default range;
