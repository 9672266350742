// @ts-nocheck
import React from "react";
import styles from "./basicButton.module.scss";

type Props = {
  onClick: () => void;
  children: any;
  className?: string;
  cypress?: string | null;
};
const BasicButton = ({
  onClick,
  children,
  className = "",
  cypress = null
}: Props) => {
  return (
    <button
      className={`${className} ${styles.base}`}
      onClick={onClick}
      data-cy={cypress}
      type="button"
    >
      {children}
    </button>
  );
};
export default BasicButton;
