/* eslint-disable indent */
// @ts-nocheck
import dayjs, { Dayjs } from "dayjs";
import preciseDiff from "dayjs-precise-range";

dayjs.extend(preciseDiff);

const pluralize = (amount: number, string: string, pluralString: string) => {
  if (amount > 1) {
    return ` ${pluralString}`;
  }

  return ` ${string}`;
};

const calculateDuration = (
  start: Dayjs,
  end: Dayjs,
  fullDescription = false,
  t: (arg1: string, arg2: string) => string = (key, defaultString) =>
    defaultString
): string => {
  if (start && end) {
    const secondTranslated = t("duration:second", "second");
    const secondsTranslated = t("duration:seconds", "seconds");
    const minuteTranslated = t("duration:minute", "minute");
    const minutesTranslated = t("duration:minutes", "minutes");
    const hourTranslated = t("duration:hour", "hour");
    const hoursTranslated = t("duration:hours", "hours");
    const dayTranslated = t("duration:day", "day");
    const daysTranslated = t("duration:days", "days");
    const monthTranslated = t("duration:month", "month");
    const monthsTranslated = t("duration:months", "months");
    const yearTranslated = t("duration:year", "year");
    const yearsTranslated = t("duration:years", "years");

    const minuteFullyAbbreviatedTranslated = t(
      "duration:minuteFullyAbbreviated",
      "m"
    );
    const minuteAbbreviatedTranslated = t("duration:minuteAbbreviated", "min");
    const minutesAbbreviatedTranslated = t(
      "duration:minutesAbbreviated",
      "mins"
    );
    const hoursAbbreviatedTranslated = t("duration:hoursAbbreviated", "h");
    const daysAbbreviatedTranslated = t("duration:daysAbbreviated", "d");
    const monthsAbbreviatedTranslated = t("duration:monthsAbbreviated", "mo");
    const yearsAbbreviatedTranslated = t("duration:yearsAbbreviated", "y");

    const diff = dayjs.preciseDiff(end, start, true);

    const { years, months, days, hours, minutes, seconds } = diff;

    const underMinute =
      !years && !months && !days && !hours && !minutes && seconds;
    const underHour = !years && !months && !days && !hours && minutes;
    const underDay = !years && !months && !days && hours;
    const underYear = !years && months;

    let mDisplay;
    let minutesDisplay;
    let hoursDisplay;
    let daysDisplay;
    let displayMonth;
    let displayYear;
    let secondsDisplay;
    let secDisplay;

    if (fullDescription) {
      secDisplay = underHour
        ? pluralize(seconds, secondTranslated, secondsTranslated)
        : "";
      secondsDisplay = underMinute
        ? pluralize(seconds, secondTranslated, secondsTranslated)
        : secDisplay;
      mDisplay = underDay
        ? pluralize(minutes, minuteTranslated, minutesTranslated)
        : "";
      minutesDisplay = underHour
        ? pluralize(minutes, minuteTranslated, minutesTranslated)
        : mDisplay;
      hoursDisplay =
        underYear || years
          ? ""
          : pluralize(hours, hourTranslated, hoursTranslated);
      daysDisplay = years ? "" : pluralize(days, dayTranslated, daysTranslated);
      displayMonth = pluralize(months, monthTranslated, monthsTranslated);
      displayYear = pluralize(years, yearTranslated, yearsTranslated);
    } else {
      mDisplay = underDay ? minuteFullyAbbreviatedTranslated : "";
      minutesDisplay = underHour
        ? pluralize(
            minutes,
            minuteAbbreviatedTranslated,
            minutesAbbreviatedTranslated
          )
        : mDisplay;
      hoursDisplay = underYear || years ? "" : hoursAbbreviatedTranslated;
      daysDisplay = years ? "" : daysAbbreviatedTranslated;
      displayYear = yearsAbbreviatedTranslated;
      displayMonth = monthsAbbreviatedTranslated;
    }

    const dict = {
      years: displayYear,
      months: displayMonth,
      days: daysDisplay,
      hours: hoursDisplay,
      minutes: minutesDisplay,
      seconds: secondsDisplay
    } as const;

    return Object.keys(diff)
      .filter((key) => key !== "firstDateWasLater" && diff[key])
      .map((key) => {
        const val = diff[key];
        const label = dict[key];

        if (!label) return null;

        return `${val}${label}`;
      })
      .join(" ")
      .trim();
  }

  return "---";
};

export default calculateDuration;
