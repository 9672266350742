// @ts-nocheck
import useShouldEnableNative from "./useShouldEnableNative";
import useMatchMediaMatches from "./useMatchMediaMatches";

const useNativeInputs = () => {
  const isTouch = useShouldEnableNative();
  const mobileSize = useMatchMediaMatches("(max-width: 639px)");
  return isTouch && mobileSize;
};
export default useNativeInputs;
