// @ts-nocheck
import React, { useState } from "react";
import dayjs from "dayjs";
import dateLeadingZero from "@justpark/helpers/src/dateLeadingZero/dateLeadingZero";
import isBetween from "dayjs/plugin/isBetween";
import RightChevronIcon from "../Icons/RightChevronIcon";
import Typography from "../Typography";
import calendarDays from "../../helpers/calendarDays";
import useGetVisibleMonthData from "./useGetVisibleMonthData";
import getCalendarDateData from "./getCalendarDateData";
import styles from "./calendar.module.scss";

dayjs.extend(isBetween);
type actionType = "prev" | "next";
type CalendarProps = {
  date: string;
  onChange: () => void;
  minDate?: string;
  maxDate?: string;
  endDate?: string | null;
};
type HeaderProps = {
  title: string;
  onMonthChange: (state: actionType) => void;
};
type ItemProps = {
  currentMonthData: [];
  firstDayOfMonth: number;
  onChange: () => void;
  startDate: string;
  visibleMonth: number;
  visibleYear: number;
  minDate: string | null;
  maxDate: string | null;
  endDate: string | null;
};
const cypress = "calendar";
const weekdays = calendarDays();
const CalendarMonthHeader = ({ title, onMonthChange }: HeaderProps) => (
  <div className={`${styles.month} CalendarDay__month-title`}>
    <Typography className={styles.monthTitle} bold>
      <span data-cy={`${cypress}-title`}>{title}</span>
    </Typography>
    <button
      className={`${styles.prev} `}
      onClick={() => onMonthChange("prev")}
      type="button"
      aria-label="Move backward to switch to the previous month."
      data-cy={`${cypress}-prev-button`}
    >
      <RightChevronIcon />
    </button>
    <button
      className={`${styles.next} `}
      onClick={() => onMonthChange("next")}
      type="button"
      aria-label="Move forward to switch to the next month."
      data-cy={`${cypress}-next-button`}
    >
      <RightChevronIcon />
    </button>
  </div>
);
const CalendarItem = ({
  currentMonthData,
  firstDayOfMonth,
  onChange,
  startDate,
  visibleMonth,
  visibleYear,
  minDate,
  maxDate,
  endDate
}: ItemProps) => {
  const {
    currentDay,
    currentMonth,
    currentYear,
    selectedDay,
    selectedMonth,
    selectedYear
  } = getCalendarDateData(startDate);
  const {
    selectedDay: selectedEndDay,
    selectedMonth: selectedEndMonth,
    selectedYear: selectedEndYear
  } = !!endDate && getCalendarDateData(endDate);
  const style = {
    gridColumnStart: firstDayOfMonth === 0 ? 7 : firstDayOfMonth
  };
  return (
    <div className={`CalendarMonth ${styles.days} ${styles.grid}`}>
      {currentMonthData.map((_, index) => {
        const day = index + 1;
        const monthString = dateLeadingZero(visibleMonth + 1);
        const dateString = dateLeadingZero(day);
        const currentDate = `${visibleYear}-${monthString}-${dateString}`;
        const isToday =
          currentDay === day &&
          currentMonth === visibleMonth &&
          currentYear === visibleYear;
        const isStartSelected =
          selectedDay === day &&
          selectedMonth === visibleMonth &&
          selectedYear === visibleYear;
        const isEndSelected =
          endDate &&
          selectedEndDay === day &&
          selectedEndMonth === visibleMonth &&
          selectedEndYear === visibleYear;
        const isSelected = isStartSelected || isEndSelected;
        const dayDate = dayjs(currentDate);
        const isBeforeMinimum = dayDate.isBefore(minDate);
        const isAfterMaximum = dayDate.isAfter(maxDate);
        const isWithinSelected = dayDate.isBetween(startDate, endDate);
        const selectedDateId =
          (endDate && isStartSelected && "CalendarDay__start-date") ||
          (isEndSelected && "CalendarDay__end-date") ||
          null;
        const formatedDayDate = dayDate.format("dddd, MMMM D, YYYY");
        const arialLabelText =
          (endDate && isStartSelected && "Selected as start date. ") ||
          (isEndSelected && "Selected as end date. ") ||
          (((!endDate && isStartSelected) || isWithinSelected) &&
            "Selected. ") ||
          "";
        const arialLabel = `${arialLabelText}${formatedDayDate}`;
        const weekday = dayDate.format("dddd");

        if (isBeforeMinimum || isAfterMaximum) {
          return (
            <div
              aria-label={arialLabel}
              key={currentDate}
              id={selectedDateId}
              className={`${styles.item} ${weekday} ${styles.past} ${
                isSelected ? `${styles.selected} CalendarDay__selected` : ""
              } ${isWithinSelected ? "CalendarDay__range-date" : ""}`}
              style={index === 0 ? style : {}}
              data-cy={`${isSelected ? `${cypress}-item-selected` : ""}`}
            >
              {day}
            </div>
          );
        }
        return (
          <button
            aria-label={arialLabel}
            key={currentDate}
            id={selectedDateId}
            type="button"
            className={`CalendarDay ${styles.item} ${weekday} ${
              isToday ? `${styles.today} CalendarDay_today` : ""
            } ${isSelected ? `${styles.selected} CalendarDay__selected` : ""} ${
              isWithinSelected ? "CalendarDay__range-date" : ""
            }`}
            style={index === 0 ? style : {}}
            onClick={() => onChange(currentDate)}
            data-cy={`${isSelected ? `${cypress}-item-selected` : ""}`}
          >
            <div className={styles.inner}>{day}</div>
          </button>
        );
      })}
    </div>
  );
};
const CalendarWeekDays = () => {
  return (
    <div className={styles.weekdayGrid}>
      {weekdays.map((weekday) => {
        return (
          <Typography key={weekday} className={styles.weekday} color="light">
            <span data-cy="calendar-weekday">{weekday}</span>
          </Typography>
        );
      })}
    </div>
  );
};
const Calendar = ({
  date,
  onChange,
  minDate = null,
  maxDate = null,
  endDate = null
}: CalendarProps) => {
  const [visibleMonthInstance, setVisibleMonthInstance] = useState(() =>
    date ? dayjs(date) : dayjs()
  );
  const {
    title,
    currentMonthData,
    firstDayOfMonth,
    visibleMonth,
    visibleYear,
    onMonthChange
  } = useGetVisibleMonthData({
    visibleMonthInstance,
    setVisibleMonthInstance
  });
  return (
    <div className={styles.calendarContainer} data-cy={cypress}>
      <CalendarMonthHeader title={title} onMonthChange={onMonthChange} />
      <CalendarWeekDays />
      <CalendarItem
        currentMonthData={currentMonthData}
        firstDayOfMonth={firstDayOfMonth}
        onChange={onChange}
        startDate={date}
        visibleMonth={visibleMonth}
        visibleYear={visibleYear}
        minDate={minDate}
        maxDate={maxDate}
        endDate={endDate}
      />
    </div>
  );
};

export default Calendar;
