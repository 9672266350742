import mapMinutes from "./mapMinutes";
import range from "./range";
import convertToDayjs from "../dates/convertToDayjs";

const mapHourRange = (minuteStep, t) =>
  range(0, 24)
    .map((hourValue) => {
      const minutes = mapMinutes(minuteStep);

      return minutes.map(({ value: minuteValue }) => {
        const value = `${`0${hourValue}`.substr(-2)}:${minuteValue}`;

        const dateInstance = convertToDayjs(`2099-01-01 ${value}:00`);

        return {
          value,
          label: t("dateFormat:time", "{{date, HH:mm}}", { date: dateInstance })
        };
      });
    })
    .flat();

export default mapHourRange;
