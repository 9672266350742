import dayjs from "dayjs";
import localDateTimeIso8601 from "../localDateTimeIso8601/localDateTimeIso8601";

const roundDate = (dateInstance, step, toFormat) => {
  const minutes = step * 60 * 1000; /* ms */
  const rounded = dayjs(Math.ceil(+dateInstance / minutes) * minutes);

  return toFormat ? rounded.format(localDateTimeIso8601) : rounded;
};

export default roundDate;
