// @ts-nocheck
import React from "react";
import dayjs from "dayjs";
import localDateTimeIso8601 from "@justpark/helpers/src/localDateTimeIso8601/localDateTimeIso8601";
import mapHourRange from "@justpark/helpers/src/mapRange/mapHours";
import roundDate from "@justpark/helpers/src/roundDate/roundDate";
import DropDown from "../DropDown";

type Props = {
  date: string;
  onTimeChange: (a: string) => void;
  className?: string;
  cypress?: string;
  t?: () => void;
};
const minuteStep = 30;
const TimeSelect = ({
  date,
  onTimeChange,
  className = "",
  cypress = "",
  t
}: Props) => {
  const timeRange = mapHourRange(minuteStep, t);
  const currentDayjsInstance = dayjs(date);
  const roundedTime = roundDate(currentDayjsInstance, 30, false).format(
    "HH:mm"
  );
  const onChange = (newValue) => {
    const [hours, minutes] = newValue.split(":");
    const updatedDayjsInstance = currentDayjsInstance
      .set("minutes", minutes)
      .set("hours", hours);
    onTimeChange(updatedDayjsInstance.format(localDateTimeIso8601));
  };
  return (
    <DropDown
      className={className}
      value={roundedTime}
      onChange={onChange}
      options={timeRange}
      cypress={cypress}
    />
  );
};
export default TimeSelect;
