// @ts-nocheck
import React, { useCallback, useMemo } from "react";
import type { Dayjs } from "dayjs";
import FancyDropdown from "../FancyDropdown";
import DropDown from "../DropDown";
import useNativeInputs from "../../hooks/useNativeInputs";

type Props = {
  options: Array<{
    label: string;
    value: string | number;
    disabled?: boolean;
  }>;
  selected: string | number;
  onChange: (a: string | number) => void;
  placeholder?: null | string;
  disabled?: boolean;
  id?: string;
  cypress?: string;
  label?: string;
  labelColor?: string;
  error?: string;
  searchable?: boolean;
  hero?: boolean;
  t: Dayjs;
};
const NativeFancyDropdown = ({
  selected,
  options,
  onChange,
  placeholder,
  disabled,
  id = null,
  cypress = null,
  label,
  labelColor = "light",
  error = "",
  searchable = false,
  hero = false,
  t
}: Props) => {
  const baseClass = "c-native-fancy-dropdown";
  const useNative = useNativeInputs();
  const selectedOption = useMemo(
    () =>
      options.find((option) => {
        return option.value === selected;
      }),
    [options, selected]
  );
  const onDropdownChange = useCallback(
    (option) => {
      onChange(option);
    },
    [onChange]
  );
  const onFancyChange = useCallback(
    (option) => {
      onChange(option.value);
    },
    [onChange]
  );
  return (
    <div className={baseClass}>
      {useNative === false ? (
        <FancyDropdown
          searchable={searchable}
          onOptionSelect={onFancyChange}
          selectedOption={selectedOption}
          options={options}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          cypress={cypress}
          label={label}
          labelColor={labelColor}
          error={error}
          hero={hero}
          t={t}
        />
      ) : (
        <DropDown
          error={error}
          onChange={onDropdownChange}
          options={options}
          value={selected}
          placeHolder={placeholder}
          disabled={disabled}
          id={id}
          cypress={cypress}
          label={label}
          labelColor={labelColor}
          hero={hero}
          t={t}
        />
      )}
    </div>
  );
};

export default NativeFancyDropdown;
