// @ts-nocheck
import React from "react";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import localDateTimeIso8601 from "@justpark/helpers/src/localDateTimeIso8601/localDateTimeIso8601";
import TimeSelect from "../TimeSelect";
import styles from "./datePickerWithTimeDropDown.module.scss";
import Calendar from "../Calendar";
import Typography from "../Typography";
import Button from "../Button";

type Props = {
  onDateChange: (a: string) => void;
  date: string;
  showTimePicker: boolean;
  onClose: () => void;
  buttonText: string;
  minDate: string;
  maxDate: string;
  timeText: string;
  isFullscreen?: boolean;
  cypress?: string;
  t?: Dayjs;
};
const DatePickerWithTimeDropDown = ({
  onDateChange,
  date,
  showTimePicker = true,
  minDate,
  maxDate,
  onClose,
  buttonText,
  timeText,
  isFullscreen = false,
  cypress = "",
  t
}: Props) => {
  const dateInstance = dayjs(date);
  const onCalendarDateChange = (newDate) => {
    const dateOnlyInstance = dayjs(newDate);
    const existingDateTime = date
      ? dayjs(date)
      : dayjs().add(1, "hour").startOf("hour");
    const newDateInstance = existingDateTime
      .set("year", dateOnlyInstance.get("year"))
      .set("month", dateOnlyInstance.get("month"))
      .set("date", dateOnlyInstance.get("date"));
    return onDateChange(newDateInstance.format(localDateTimeIso8601));
  };

  return (
    <div
      className={isFullscreen ? styles.fullScreenContainer : styles.container}
    >
      <div className={styles.datePicker}>
        <Calendar
          date={date}
          onChange={onCalendarDateChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>

      <div className={styles.footer}>
        {showTimePicker && (
          <div className={styles.timeContainer}>
            <Typography size="primary">
              <span className={styles.timeText}>{timeText}</span>
            </Typography>

            <TimeSelect
              onTimeChange={onDateChange}
              date={dateInstance.format(localDateTimeIso8601)}
              className={styles.time}
              cypress={`${cypress}-time-select`}
              t={t}
            />
          </div>
        )}

        <Button
          type="button"
          onClick={onClose}
          className={styles.button}
          cypress="datepicker-close-button"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
export default DatePickerWithTimeDropDown;
