// @ts-nocheck
import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import firstDayOfMonth from "@justpark/helpers/src/firstDayOfMonth/firstDayOfMonth";

type Props = {
  visibleDateInstance: Dayjs;
  setVisibleDate: () => void;
};
const useGetVisibleMonthData = ({
  visibleMonthInstance,
  setVisibleMonthInstance
}: Props) => {
  const title = visibleMonthInstance.format("MMMM YYYY");
  const currentMonthData = useMemo(() => {
    const daysInMonth = visibleMonthInstance.daysInMonth();
    return Array(daysInMonth).fill(0);
  }, [visibleMonthInstance]);
  const visibleMonth = visibleMonthInstance.get("month");
  const visibleYear = visibleMonthInstance.get("year");
  const onMonthChange = useCallback(
    (actionType: "prev" | "next") => {
      const state = actionType === "next" ? 1 : -1;
      setVisibleMonthInstance((prev) => {
        return dayjs(prev.add(state, "month"));
      });
    },
    [setVisibleMonthInstance]
  );
  return {
    title,
    currentMonthData,
    firstDayOfMonth: firstDayOfMonth(visibleMonth, visibleYear),
    visibleMonth,
    visibleYear,
    onMonthChange
  };
};
export default useGetVisibleMonthData;
