// @ts-nocheck

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjsDuration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { transformLocale } from "./convertToDayjsWithTimezone";

dayjs.extend(isSameOrBefore);
dayjs.extend(dayjsDuration);
dayjs.extend(timezone);
dayjs.extend(utc);

const getDayjsInstanceWithTimezone = (locale = "en-GB", tz = "Europe/London") =>
  dayjs().locale(transformLocale(locale)).tz(tz);

export default getDayjsInstanceWithTimezone;
